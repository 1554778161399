:root {
    --animate-duration: 0.2s;
    --animate-delay   : 0.2s;
    --animate-repeat  : 1;
}

%animate__animated {
    -webkit-animation-duration : var(--animate-duration);
    animation-duration         : var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode        : both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fp-fadeIn {
    @extend %animate__animated;
    -webkit-animation-name: fadeIn;
    animation-name        : fadeIn;
}