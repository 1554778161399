@mixin radio-button-generic {
    display       : flex;
    flex-direction: column;

    .radio-label {
        font-stretch  : normal;
        font-style    : normal;
        letter-spacing: 0.22px;
        width         : 100%;
        height        : 24px;
        font-size     : 0.875rem;
        font-weight   : 600;
        line-height   : 2;
        color         : var(--evergreen);
        font-family   : sofiaProLight, sans-serif;
    }

    .radio-group {
        display       : flex;
        flex-direction: row;
    }

    .radio-type-option {
        flex-wrap   : wrap;
        margin-right: -7.5px;
        margin-left : -7.5px;
        margin-top  : -5px;
    }

    .conten-radio-generic {
        flex   : 0 0 calc(50% - 15px);
        padding: 0.4rem 7.5px 7.5px;
    }

    .input-radio-option {
        width           : 100%;
        height          : 48px;
        text-align      : left;
        padding-left    : 16px;
        border-radius   : 8px;
        border          : solid 1.5px var(--color-tertiary-white-solid-w-50);
        background-color: var(--cards-solid-card-solid-elevation-0);
        box-shadow      : 0 1px 2px 0 rgba(33, 33, 33, 0.14);
        color           : #677379;
        display         : flex;
        flex-direction  : row;
        align-items     : center;

        &:focus {
            outline: none !important;
        }

        &.disabled {
            background-color: var(--color-tertiary-white-solid-w-50);
        }
    }

    .radio-btn-container {
        display        : flex;
        justify-content: flex-end;
        width          : 100%;
        margin         : 8px 8px 10px 10px;
    }

    .space-inputs-small {
        padding-top: 13px;
    }

    .selected-option {
        border    : solid 1.5px var(--color-secundary-green-solid-g-600-ba);
        color     : var(--evergreen);
        box-shadow: 0 8px 16px 0 rgba(128, 186, 23, 0.14);

        &.disabled {
            border          : solid 1.5px var(--color-neutral-carbon-solid-c-light-6);
            background-color: var(--color-tertiary-white-solid-w-50);
            box-shadow      : 0 4px 8px 0 rgba(33, 33, 33, 0.14);
        }
    }
}