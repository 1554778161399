@use "igniteui-angular/theming" as *;
@import "./styles/variables";
@import "./styles/fonts";
@import "mixin-element";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "./styles/animated";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@include core();
@include typography($font-family: $material-typeface, $type-scale: $material-type-scale);
@include theme($default-palette);
body {
    margin             : 0;
    overflow-x         : hidden;
    -moz-user-select   : none;
    -webkit-user-select: none;
    -ms-user-select    : none;
    user-select        : none;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin            : 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input[type="password"] {
    font-family            : "fontello", sans-serif;
    font-style             : normal;
    font-weight            : normal;
    font-variant           : normal;
    text-transform         : none;
    -webkit-font-smoothing : antialiased;
    font-size              : 8px;
    -moz-osx-font-smoothing: grayscale;
}

/* chrome elimina backround de los input al llenar con sugerencia */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: 1000s ease-in-out 0s;
}


.mat-snack-bar-container {
    padding         : 0px !important;
    border-radius   : 10px !important;
    margin          : 25px 19px 0px 17px !important;
    box-shadow      : 0 16px 24px 0 rgba(33, 33, 33, 0.14) !important;
    background-color: transparent !important;
    min-width       : initial !important;
    min-height      : initial !important;
    max-width       : initial !important;

    @include for-tablet-portrait-up {
        margin: 25px 25px 0px 25px !important;
    }

    @include for-desktop-up {
        margin: 25px 25px 0px 25px !important;
    }
}

.fp-scrollbar {
    --scrollbar-color            : var(--color-secundary-bluedark-solid-bd-50) !important;
    --scrollbar-thumb-color      : #addc71 !important;
    --scrollbar-thumb-hover-color: #93bd5d !important;
    --scrollbar-size             : 2px !important;
    --scrollbar-track-color      : #ecedee !important;
    --scrollbar-padding          : 8px !important;
    --scroll-view-margin         : 0 !important;
}


.no-overflow {
    overflow: hidden;
}

.loader-active {
    height  : 100vh;
    overflow: hidden;
}

.mat-select-panel {
    box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14) !important;
}

.fp-address-select {
    font-family   : SofiaProLight, sans-serif !important;
    font-size     : 14px !important;
    font-stretch  : normal !important;
    font-style    : normal !important;
    line-height   : 1.71 !important;
    letter-spacing: 0.22px !important;
    color         : var(--color-neutral-carbon-solid-c-mid-6-2) !important;
    z-index       : 1002 !important;

    .mat-option-text {
        color: var(--color-neutral-carbon-solid-c-mid-6-2) !important;
    }
}

.fp-snack-modify {
    @include for-tablet-portrait-up {
        margin-left: 12rem !important;
    }

    @include for-desktop-up {
        margin-left: 12rem !important;
    }
}

.form-group-submit {
    width   : 100%;
    position: fixed;
    bottom  : 0px;
    left    : 0px;

    .fp-container-btn {
        padding         : 24px 16px;
        background-color: rgba(255, 255, 255, 0.4);

        @include for-tablet-portrait-up {
            justify-content : center;
            display         : flex;
            width           : 100%;
            background-color: transparent;
            padding         : 0;
        }

        @include for-desktop-up {
            justify-content : center;
            display         : flex;
            width           : 100%;
            background-color: transparent;
            padding         : 0;
        }
    }

    @include for-tablet-portrait-up {
        position       : relative;
        display        : flex;
        justify-content: center;
        margin-top     : 9px;
        margin-bottom  : 1rem;
        z-index        : 0;
    }

    @include for-desktop-up {
        position       : relative;
        display        : flex;
        justify-content: center;
        margin-top     : 9px;
        margin-bottom  : 1rem;
        z-index        : 0;
    }
}

.cdk-overlay-container {
    z-index: 1001 !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.no-permision {

    &:hover {
        &::after {
            font-family  : SofiaProLight, sans-serif;
            position     : absolute;
            content      : "Solicite el permiso al administrador del portal";
            color        : var(--cards-solid-card-solid-elevation-0);
            font-size    : 0.875rem;
            background   : var(--fp-modal-background);
            padding      : 8px;
            border-radius: 8px;
            top          : -89%;
            left         : 35%;
            width        : max-content;
            z-index      : 1;
        }

        &::before {
            content     : " ";
            position    : absolute;
            left        : 50%;
            border-width: 5px;
            border-style: solid;
            border-color: var(--fp-modal-background) transparent transparent transparent;
            z-index     : 1;
        }
    }
}

.no-enable-option {

    &:hover {
        &::after {
            font-family  : SofiaProLight, sans-serif;
            position     : absolute;
            content      : "Comuniquese con Facilpass";
            color        : var(--cards-solid-card-solid-elevation-0);
            font-size    : 0.875rem;
            background   : var(--fp-modal-background);
            padding      : 8px;
            border-radius: 8px;
            top          : -89%;
            left         : 35%;
            width        : max-content;
            z-index      : 1;
        }

        &::before {
            content     : " ";
            position    : absolute;
            left        : 50%;
            border-width: 5px;
            border-style: solid;
            border-color: var(--fp-modal-background) transparent transparent transparent;
            z-index     : 1;
        }
    }
}
html { height: 100%; }
