:root {
    --color-alert-info-solid-b-ibase      : #00a6fb;
    --color-tertiary-white-solid-w-50     : #f9f9f9;
    --cards-solid-card-solid-elevation-0  : #ffffff;
    --color-neutral-gray-solid-g-600      : #9e9e9e;
    --color-primary-blue-solid-b-800      : #5a78b0;
    --color-primary-blue-solid-b-50       : #eff0f4;
    --color-tertiary-white-solid-w-200    : #faf9f9;
    --color-primary-blue-solid-b-400      : #34a6d3;
    --color-alert-danger-solid-r-dbase    : #ff3144;
    --color-secundary-bluedark-solid-bd-50: #ecedee;
    --color-alert-success-solid-g-sbase   : #1c6d29;
    --color-primary-blue-solid-bbase      : #32579e;
    --button-specs-state-primary-normal   : rgba(255, 255, 255, 0);
    --evergreen                           : #121212;
    --color-primary-blue-solid-b-600-base : #0077b6;
    --color-secundary-green-solid-g-600-ba: #80ba16;
    --color-semantic-warning-solid-sw-50  : #fef6cb;
    --color-semantic-information-solid-s-2: #0075be;
    --color-semantic-information-solid-s-3: #3abeea;
    --color-neutral-carbon-solid-c-light-6: #ebebeb;
    --color-semantic-error-solid-se-600-s : #b1120e;
    --color-semantic-warning-solid-sw-400 : #fbcb3e;
    --color-semantic-success-solid-ss-400 : #57b255;
    --color-input-border-gray             : #c8ccce;
    --color-neutral-carbon-solid-c-mid-6-2: #555555;
    --fp-border-radius                    : 1rem;
    --fp-title                            : #014583;
    --fp-body-one                         : #121212;
    --fp-modal-text                       : #677379;
    --fp-modal-link                       : #55a630;
    --fp-modal-background                 : #000000;
    --fp-color-black                      : #121212;
    --fp-btn-primary-hover                : #006398;
    --fp-btn-primary-focus                : #006398;
    --fp-btn-primary-active               : #004c74;
    --fp-btn-secundary-focus              : #cdf6fb;
    --fp-btn-ghost-hover                  : #20710d;
    --fp-btn-ghost-focus                  : #0c4921;
    --fp-btn-ghost-disabled               : #d9d9d9;
    --color-semantic-information-solid-si : #cafafd;
    --color-semantic-error-1              : #fce2ce;
    --color-semantic-error-2              : #b3182d;
    --color-semantic-warning-solid-sw-800 : #905b00;
    --fp-color-active                     : #d5ff67;
    --color-semantic-warning-solid-sw-60-2: #d69300;
    --fp-btn-background-tertiary          : #c9f6fb;
    --fp-border                           : #001f6c;
    --fp-color-validator                  : #e0f8d6;
    --fp-skeleton                         : #f2f2f2;
    --fp-btn-green--hover                 : #6b9b15;
    --fp-btn-green--active                : #366a1e;
    --fp-black-suede                      : #434343;
    --fp-shadow-tooltip                   : #0362A626;
    --fp-intercoastal-grey                : #A9B4BC;
    --fp-brilliant-white                  : #E5F1FF;
    --fp-blue-sparkle                     : #0076FF;
    --fp-credit-card-shadow               : #01458326;
    --fp-deepest-sea                      : #434E56;
    --fp-admin-menu-hover-shadow          : #80BA1726;
    --fp-icon-message-shadow              : #0362A626;
    --fp-content-message-shadow           : #01458326;
    --fp-description-message-info         : #006099;
    --fp-title-message-info               : #0262a6;
    --fp-cool-grey                        : #9D9D9D;
    --fp-tertiary-grey                    : #7B7B7B;
    --fp-selected-card                    : #80ba17;
}
