  @mixin for-phone-only {
    @media (min-width: 375px) and (max-width: 767px) {
      @content;
    }
  }

  @mixin for-tablet-portrait-up {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  }

  @mixin for-desktop-up {
    @media (min-width: 1200px){
      @content;
    }
  }
