@mixin fp-btn-primary {
  background     : var(--color-primary-blue-solid-b-600-base);
  height         : 48px;
  font-size      : 0.875rem;
  font-family    : SofiaProRegular, sans-serif;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 1.14;
  letter-spacing : 0.22px;
  text-align     : center;
  color          : var(--cards-solid-card-solid-elevation-0);
  border-radius  : 12px;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  border-color   : transparent;
  width          : 100%;
  cursor         : pointer;
  outline        : none;

  &:hover:not(:disabled):not(.disabled) {
    background-color: var(--fp-btn-primary-hover);
    box-shadow      : none;
  }

  &:active:not(:disabled):not(.disabled) {
    background-color: var(--fp-btn-primary-focus);
    box-shadow      : 0 8px 16px 0 rgba(0, 119, 182, 0.14);
  }

  &:disabled,
  &.disabled {
    background-color: var(--color-secundary-bluedark-solid-bd-50);
    color           : var(--color-neutral-carbon-solid-c-mid-6-2);
    cursor          : default;
    pointer-events  : none;
  }
}

@mixin fp-btn-secondary {
  background     : transparent;
  height         : 48px;
  font-size      : 0.875rem;
  font-family    : SofiaProRegular, sans-serif;
  font-weight    : 600;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 1.23;
  letter-spacing : 0.22px;
  text-align     : center;
  color          : var(--color-primary-blue-solid-b-600-base);
  border-radius  : 12px;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  border         : 1px solid var(--color-primary-blue-solid-b-600-base);
  width          : 100%;
  cursor         : pointer;
  outline        : none;

  &:hover:not(:disabled):not(.disabled) {
    border          : 1px solid var(--color-primary-blue-solid-b-400);
    background-color: var(--fp-btn-secundary-focus);
    box-shadow      : none;
  }

  &:active:not(:disabled):not(.disabled) {
    border          : 1px solid var(--fp-btn-primary-focus);
    background-color: var(--fp-btn-primary-focus);
    color           : var(--cards-solid-card-solid-elevation-0);
    box-shadow      : 0 7px 17px 0px #95b8cc;
  }

  &:disabled,
  &.disabled {
    border          : 1px solid var(--color-secundary-bluedark-solid-bd-50);
    background-color: var(--color-secundary-bluedark-solid-bd-50);
    color           : var(--color-neutral-carbon-solid-c-mid-6-2);
    cursor          : default;
    pointer-events  : none;
  }
}

@mixin fp-btn-tertiary {
  background     : var(--fp-btn-background-tertiary);
  height         : 48px;
  font-size      : 0.8125rem;
  font-family    : SofiaProRegular, sans-serif;
  font-weight    : 600;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 1.23;
  letter-spacing : 1.25px;
  text-align     : center;
  color          : var(--color-primary-blue-solid-b-600-base);
  border-radius  : 12px;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  border         : 1px solid var(--fp-btn-background-tertiary);
  width          : 100%;
  cursor         : pointer;
  outline        : none;

  &:hover:not(:disabled):not(.disabled) {
    border          : 1px solid var(--fp-btn-primary-hover);
    background-color: var(--fp-btn-primary-hover);
    box-shadow      : none;
    color           : var(--cards-solid-card-solid-elevation-0);
  }

  &:active:not(:disabled):not(.disabled) {
    border          : 1px solid var(--fp-btn-primary-focus);
    background-color: var(--fp-btn-primary-focus);
    box-shadow      : 0 7px 17px 0px #95b8cc;
    color           : var(--cards-solid-card-solid-elevation-0);

  }

  &:disabled,
  &.disabled {
    border          : 1px solid var(--color-secundary-bluedark-solid-bd-50);
    background-color: var(--color-secundary-bluedark-solid-bd-50);
    color           : var(--color-neutral-carbon-solid-c-mid-6-2);
    cursor          : default;
    pointer-events  : none;
  }
}

@mixin fp-btn-green {
  background     : var(--color-secundary-green-solid-g-600-ba);
  height         : 40px;
  font-size      : 0.875rem;
  font-family    : SofiaProRegular, sans-serif;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 1.14;
  letter-spacing : 0.22px;
  text-align     : center;
  color          : var(--cards-solid-card-solid-elevation-0);
  border-radius  : 12px;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  border-color   : transparent;
  width          : 100%;
  cursor         : pointer;
  outline        : none;

  &:hover:not(:disabled):not(.disabled) {
    background-color: var(--fp-btn-green--hover);
    box-shadow      : none;
  }

  &:active:not(:disabled):not(.disabled) {
    background-color: var(--fp-btn-green--active);
    box-shadow      : 0 8px 16px 0 rgba(128, 186, 23, 0.14);

  }

  &:disabled,
  &.disabled {
    background-color: var(--color-secundary-bluedark-solid-bd-50);
    color           : var(--color-neutral-carbon-solid-c-mid-6-2);
    cursor          : default;
    pointer-events  : none;
  }
}

@mixin fp-btn-ghost {
  cursor          : pointer;
  font-family     : SofiaProSemiBold, sans-serif;
  display         : flex;
  justify-content : center;
  font-size       : 0.813rem;
  font-stretch    : normal;
  font-style      : normal;
  line-height     : 1.71;
  letter-spacing  : 0.014rem;
  color           : var(--fp-modal-link);
  width           : 100%;
  height          : 48px;
  text-align      : center;
  border-radius   : 12px;
  flex-direction  : row;
  align-items     : center;
  border-color    : transparent;
  background-color: transparent;
  outline         : none;

  &:hover:not(:disabled):not(.disabled) {
    color           : var(--fp-btn-ghost-hover);
    background-color: transparent;
  }

  &:active:not(:disabled):not(.disabled) {
    color           : var(--fp-btn-ghost-focus);
    background-color: var(--color-neutral-carbon-solid-c-light-6);
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    color           : var(--fp-btn-ghost-disabled);
    cursor          : default;
    pointer-events  : none;
  }
}

@mixin fp-link-button {
  color      : var(--color-semantic-information-solid-s-2);
  font-family: SofiaProBoldMedium, sans-serif;
  font-size  : 14px;
  cursor     : pointer;

  &:hover:not(:disabled):not(.disabled) {
    color: var(--color-semantic-information-solid-s-3);
  }

  &:focus:not(:disabled):not(.disabled) {
    color: var(--fp-title);
  }

  &:active:not(:disabled):not(.disabled) {
    color: var(--fp-title);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color         : var(--fp-btn-ghost-disabled);
  }
}

@mixin fp-btn-white {
  color          : var(--color-semantic-information-solid-s-2);
  cursor         : pointer;
  background     : var(--cards-solid-card-solid-elevation-0);
  font-family    : SofiaProSemiBold, sans-serif;
  display        : flex;
  justify-content: center;
  font-stretch   : normal;
  font-style     : normal;
  font-size      : 13px;
  line-height    : 1.23;
  letter-spacing : 1px;
  text-align     : center;
  border-radius  : 8px;
  box-shadow     : 0 1px 2px 0 rgba(33, 33, 33, 0.14);
  flex-direction : row;
  align-items    : center;
  border         : 0px solid transparent;
  outline        : none;
  padding        : 0 3px 0 10px;

  &:disabled,
  &.disabled {
    background-color: var(--color-secundary-bluedark-solid-bd-50);
    color           : var(--color-neutral-carbon-solid-c-mid-6-2);
    cursor          : default;
    pointer-events  : none;
    filter          : grayscale(100%);
  }
}

@mixin fp-btn-fap {
  width                : 48px;
  height               : 48px;
  -moz-border-radius   : 50%;
  -webkit-border-radius: 50%;
  border-radius        : 50%;
  background           : var(--color-primary-blue-solid-b-600-base);
  outline              : none;
  border               : none;
  font-family          : SofiaProRegular, sans-serif;
  cursor               : pointer;
  box-shadow           : 0 8px 16px 0 rgba(33, 33, 33, 0.14);

  &:hover:not(:disabled):not(.disabled) {
    background-color: var(--fp-btn-primary-hover);
    box-shadow      : none;
  }

  &:active:not(:disabled):not(.disabled) {
    background-color: var(--fp-btn-primary-focus);
    box-shadow      : 0 7px 17px 0px #95b8cc;
  }

  &:disabled,
  &.disabled {
    background-color: var(--color-secundary-bluedark-solid-bd-50);
    color           : var(--color-neutral-carbon-solid-c-mid-6-2);
    cursor          : default;
    pointer-events  : none;
    box-shadow      : none;
  }

}